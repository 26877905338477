const OrganizationSchema = () => {
  return (
    <>
      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "Organization",
            url: "https://leapscholar.com/",
            logo: "https://leapscholar.com/assets/company_logo/new-header-logo.svg",
            name: "LeapScholar",
            alternateName: "Leap Scholar - Study Abroad",
            description:
              "Want to Study Abroad? LeapScholar provides Personalized Guidance and information on top universities, courses, IELTS Coaching, Financial Help and Visa Assurance. Get Counselling and Mentorship for your Study abroad dream Today!",
            foundingDate: "2020",
            knowsAbout:
              "Study Abroad, Study Abroad consultant, Free Study Abroad Counselling",
            sameAs: [
              "https://www.youtube.com/c/leapscholar",
              "https://twitter.com/leapscholar",
              "https://www.linkedin.com/company/leap-global-education/",
              "https://www.instagram.com/leapscholar",
              "https://www.facebook.com/leapscholar",
              "https://t.me/leap_abroad",
            ],
            brand: {
              "@type": "Brand",
              name: "LeapScholar",
              alternateName: "Leap Scholar - Study Abroad",
            },
            contactPoint: {
              "@type": "ContactPoint",
              contactType: "Customer Support",
              telephone: "+91 8047184844",
              availableLanguage: ["English", "Hindi"],
              email: "contact@leapscholar.com",
            },
          }),
        }}
      />

      <script
        type="application/ld+json"
        dangerouslySetInnerHTML={{
          __html: JSON.stringify({
            "@context": "https://schema.org",
            "@type": "WebSite",
            name: "LeapScholar",
            alternateName: "Leap Scholar - Study Abroad",
            url: "https://leapscholar.com/",
          }),
        }}
      />
    </>
  )
}

export default OrganizationSchema
