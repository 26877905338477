import { StyledButton } from "components/Inputs/Button"
import { logEvent } from "ga"
import Image from "next/image"
import { useRouter } from "next/router"
import { useEffect, useState } from "react"
import { useSelector } from "react-redux"

import { counsellingStageRank } from "DataMapper/Profile/StageMapper"
import useDevice from "hooks/useDevice"
import { AuthToken } from "lib/auth_token"
import { navRoutes } from "page_routes"
import { trackClick } from "scripts/segment"
import { pageName } from "utils/events-utils"
import { IMG_URLS_DESKTOP, IMG_URLS_MOBILE } from "../constant"
import FirstFoldSticky from "./FirstFoldSticky"

const LandingHero = () => {
  const { isMobile } = useDevice()
  const counsellingStage = useSelector(
    (state: any) => state.spotCounselling.stage,
  )
  const authValue = useSelector((state: any) => state?.auth?.auth?.token)
  const authToken = new AuthToken(authValue)
  const isLoggedIn = authToken && authToken.isValid
  const router = useRouter()
  const [heroCtaText, setHeroCtaText] = useState("Start your journey")

  const handleCtaButton = () => {
    trackClick(pageName.LeapScholarHomePage, {
      widgetName: "Hero Section",
      widgetFormat: "Banner",
      contentName: "Start your Journey",
      contentFormat: "Button",
    })

    if (isLoggedIn) {
      if (counsellingStageRank[counsellingStage as string] <= 2) {
        logEvent("Get Expert Counselling", router.asPath, "Banner")
        router.push(navRoutes.WEBFLOW_PROGRESSIVE_FORM)
      } else {
        logEvent("Get Access", router.asPath, "Banner")
        router.push(navRoutes.POSTLOGIN_COUNSELLING)
      }
    } else {
      router.push(navRoutes.WEBFLOW_PROGRESSIVE_FORM)
    }
  }

  // TODO:: need to abstract this logic to some hoc or hook
  useEffect(() => {
    if (isLoggedIn) {
      if (counsellingStageRank[counsellingStage as string] <= 2) {
        setHeroCtaText("Start your journey")
      } else {
        setHeroCtaText("Get Access")
      }
    } else {
      setHeroCtaText("Start your journey")
    }
  }, [isLoggedIn, counsellingStage])

  return (
    <div className="w-full">
      <div
        className={`absolute h-[700px] max-h-[1000px] w-full z-10`}
        id="stage"
      >
        <Image
          src={isMobile ? IMG_URLS_MOBILE[0].url : IMG_URLS_DESKTOP[0].url}
          alt={isMobile ? IMG_URLS_MOBILE[0].alt : IMG_URLS_DESKTOP[0].alt}
          priority={true}
          placeholder="blur"
          blurDataURL="data:image/png;base64,iVBORw0KGgoAAAANSUhEUgAAAAEAAAABCAYAAAAfFcSJAAAAAXNSR0IArs4c6QAAAA1JREFUGFdjcLH7/x8ABMwCgfmZ8pQAAAAASUVORK5CYII="
          layout="fill"
          objectFit="cover"
        />
      </div>
      <div className="z-20 md:w-3/4 lg:w-1/2 w-full h-[700px] absolute">
        <div className="mx-auto w-full flex flex-col items-center justify-center md:justify-start">
          <h1 className="md:text-font56 text-center md:text-left font-bold text-font32 text-white drop-shadow-md md:mt-[80px] mt-[20px] md:ml-[10%] lg:ml-[20%]">
            Powering your global education dream
          </h1>
          <StyledButton
            ctaText={heroCtaText}
            onClick={handleCtaButton}
            className="relative z-1000 font-bold md:text-font16 text-white !py-4 w-1/2 mt-8 md:-ml-[30%] lg:-ml-[10%] hidden md:block"
          />
          <div
            className={
              "-mt-8 lg:left-[20%] md:left-[10%] relative hidden md:block"
            }
          >
            <Image
              src={`${process.env.CDN_URL}/assets/images/LSRevamp/aeroplane-landing.svg`}
              width={122}
              height={88}
            />
          </div>
          <div className="flex h-full justify-center md:justify-start items-center md:mt-6 lg:ml-[40%] md:ml-[20%] mt-6 max-w-full w-full">
            <div className="flex flex-col text-center">
              <p className="md:text-font32 text-font20 font-bold text-white">
                1,00,000+
              </p>
              <p className="md:text-font16 text-font14 font-normal text-white">
                Success Stories
              </p>
            </div>
            <div className="border-[#C1C2C7] h-[50px] border mx-4" />
            <div className="flex flex-col text-center">
              <p className="md:text-font32 text-font20 font-bold text-white">
                10,000+
              </p>
              <p className="md:text-font16 text-font14 font-normal text-white">
                Preferred Courses
              </p>
            </div>
          </div>
          <div className="absolute bottom-[130px] w-9/10 right-0 left-0 mx-auto md:hidden">
            <StyledButton
              ctaText={heroCtaText}
              onClick={handleCtaButton}
              className="font-bold md:text-font16 text-white py-4 w-full mt-8"
            />
          </div>
        </div>
      </div>
      <FirstFoldSticky isMobileSized={isMobile as boolean} />
    </div>
  )
}

export default LandingHero
